import React, { useState } from "react";
import { Row, Col, Toast, Button } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { Translation } from "react-i18next";
import moment from "moment";
import { allStates } from "./statesData";
import { getColorInFrenchLanguage, getDateInFrenchLanguage } from "./language";
const momentTimeZone = require("moment-timezone");
const CustomContentAlert = (value) => {
  const [show, setShow] = useState(true);
  return (
    <Row>
      <Col md={12}>
        <Toast style={{ position: "center", zIndex: 9999 }} onClose={() => setShow(false)} show={show} delay={value.delay} autohide className={value.className}>
          <Toast.Header style={{ position: "right" }}></Toast.Header>
          <Toast.Body>{value.message}</Toast.Body>
        </Toast>
      </Col>
    </Row>
  );
};

export const tableHeightScrollBars = () => {
  const bodyHeight = document.body.scrollHeight;
  const navbarHeight = document.getElementsByClassName("navbar")[0]?.scrollHeight ? document.getElementsByClassName("navbar")[0]?.scrollHeight : null;
  const filterHeaderHeight = document.getElementsByTagName("header")[0]?.offsetHeight;
  const footerHeight = document.getElementsByClassName("fixed-bottom")[0]?.offsetHeight;
  const finalHeight = bodyHeight - (navbarHeight + filterHeaderHeight + footerHeight + 100);
  return finalHeight + "px";
};

export const tableHeightScrollBarsTab = () => {
  const bodyHeight = document.body.scrollHeight;
  const navbarHeight = document.getElementsByClassName("navbar")[0]?.scrollHeight;
  const navtabsHeight = document.getElementsByClassName("nav-tabs")[0]?.scrollHeight;
  const navtabsHeaderHeight = document.getElementsByTagName("header")[0]?.offsetHeight;
  const footerHeight = document.getElementsByClassName("fixed-bottom")[0]?.offsetHeight;
  const finalHeight = bodyHeight - (navbarHeight + navtabsHeight + navtabsHeaderHeight + footerHeight + 144);
  return finalHeight + "px";
};

export const regExForPhone = /^([+]?[ \d]+)?(\d{3}|[(]?\d+[)])?([- ]?\d)+$/i;

export const isEmailValid = (email) => {
  const regExForEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/;
  const isEmailValidate = regExForEmail.test(String(email).toLowerCase());
  return isEmailValidate;
};

export const validation_message = (constName, msgName, validName) => {
  const errorMsg = msgName + " is Required.";
  return !constName && !validName ? (
    <div className="help-block">
      <Translation>{(t) => <>{t(errorMsg)}</>}</Translation>
    </div>
  ) : (
    <div></div>
  );
};

export const contentLoader = () => {
  return (
    <ContentLoader speed={1} backgroundColor={"#b6b6b6"} foregroundColor={"#898989"} viewBox="0 0 380 250">
      <circle cx="30" cy="50" r="30" />
      <rect x="230" y="17" rx="4" ry="4" width="150" height="20" />
      <rect x="330" y="50" rx="3" ry="3" width="50" height="20" />
      <rect x="0" y="170" rx="3" ry="3" width="200" height="40" />
    </ContentLoader>
  );
};

export const validations = (targetName, fieldNames, fieldValue) => {
  if (targetName === fieldNames && fieldValue.trim().length === 0) {
    return { value: "", validator: false };
  } else {
    return {
      value: fieldValue,
      validator: true,
    };
  }
};

export const timestamp = () => {
  function pad(n) {
    return n < 10 ? "0" + n : n;
  }
  const d = new Date();
  const dash = "-";
  const colon = ":";
  return (
    d.getFullYear() +
    dash +
    pad(d.getMonth() + 1) +
    dash +
    pad(d.getDate()) +
    "T" +
    pad(d.getHours()) +
    colon +
    pad(d.getMinutes()) +
    colon +
    pad(d.getSeconds())
  );
};

export const email_validation_message = (email, validEmail, wrongEmail, driverSide) => {
  if (driverSide) {
    const emailRequiredMsg = "EmailRequired.";
    const emailInvalidMsg = "EmailIncorrect.";
    return !email.trim().length && !validEmail ? (
      <div className="help-block">
        <Translation>{(t) => <>{t(emailRequiredMsg)}</>}</Translation>
      </div>
    ) : (
      email && wrongEmail && (
        <div className="help-block">
          {" "}
          <Translation>{(t) => <>{t(emailInvalidMsg)}</>}</Translation>
        </div>
      )
    );
  } else {
    return !email?.trim().length && !validEmail ? (
      <div className="help-block">Email is required</div>
    ) : (
      email && wrongEmail && <div className="help-block">Invalid Email</div>
    );
  }
};

export const findBySearch = (searchValue, list, oldList, columnList, timezone) => {
  let newItems = [];
  if (searchValue && searchValue.trim().length === 0) {
    newItems = oldList;
  } else {
    console.log("++++++++++++++++++++++++", list);
    (list ?? []).filter((item) => {
      const temp1 = false;
      let filteredItem;
      item &&
        (Object.keys(item) ?? []).map((ele) => {
          let tempResult;
          if (!columnList || columnList.includes(ele)) {
            if (timezone) {
              if (
                ele === "created_at" ||
                ele === "IssuedDate" ||
                ele === "time_of_action" ||
                ele === "deactivation_time" ||
                ele === "ticket_time" ||
                ele === "transaction_time" ||
                ele === "end_time" ||
                ele === "entry_time" ||
                ele === "permit_created_at" ||
                ele === "createdAt"
              ) {
                let date;
                if (ele === "IssuedDate") {
                  date = utcToSpecificTimeZone(timezone, item[ele], true);
                } else if (ele === "permit_created_at") {
                  date = moment(item[ele]).format("lll");
                } else {
                  date = utcToSpecificTimeZone(timezone, item[ele]);
                }
                const temp2 = date.toLowerCase().includes(searchValue?.toLowerCase());
                tempResult = temp1 || temp2 ? temp2 : false;
                tempResult && (filteredItem = item);
                return tempResult;
              }
            }
            if (ele === "phone_options") {
              const filterNumber = item[ele].map((i) => i.phone);
              const temp2 = filterNumber.indexOf(searchValue) === 0;
              tempResult = temp1 || temp2 ? temp2 : false;
              tempResult && (filteredItem = item);
              return tempResult;
            }
            if (typeof item[ele] === "number") {
              const temp2 = item[ele].toString().includes(searchValue);
              tempResult = temp1 || temp2 ? temp2 : false;
              tempResult && (filteredItem = item);
              return tempResult;
            } else {
              const temp2 =
                item[ele] &&
                item[ele] !== true &&
                item[ele] !== false &&
                typeof item[ele] !== "object" &&
                item[ele].trim().length &&
                item[ele].toLowerCase().includes(searchValue?.toLowerCase());
              tempResult = temp1 || temp2 ? temp2 : false;
              tempResult && (filteredItem = item);
              return tempResult;
            }
          }
          return tempResult;
        });
      filteredItem && newItems.push(filteredItem);
      return [];
    });
  }
  return newItems;
};

export const checkWord = (item, searchValue) => {
  const tolower = searchValue.toLowerCase();
  item.toLowerCase().includes(tolower);
};

export const routing_using_history = (path) => {
  const history = require("../history").default;
  history.push({
    pathname: path,
  });
};

export const lotselection = (allLots, aliasName) => {
  let lotId = "";
  // const result = allLots.filter((item) => {
  allLots.filter((item) => {
    if (item.alias === aliasName) {
      lotId = item.lot_id;
    }
    return lotId;
  });
  return lotId;
};

export const navigationButtons = (
  previousLocation,
  nextLocation,
  property_id,
  addActionRef,
  currentButton,
  isNextButtonDisable,
  isThereVisitorLot,
  handleAlertOnVisitorLot,
  addClicked,
) => {
  const disable = !previousLocation.length && true;
  const history = require("../history").default;
  let nextButtonDisablity = false;
  if (previousLocation === "/settings") {
    // || previousLocation === "/adduserform"
    nextButtonDisablity = isNextButtonDisable;
  }
  return (
    <Row
      className={
        currentButton === "Add Lot" ||
        currentButton === "Add Lane" ||
        currentButton === "Add Camera" ||
        currentButton === "Add User" ||
        currentButton === "Add Edge Device" ||
        currentButton === "Add Meter Configuration"
          ? "bottom-footer-btn m-0"
          : "bottom-footer-btn right-side m-0 mt-3"
      }
    >
      {currentButton === "Add Lot" ||
      currentButton === "Add Lane" ||
      currentButton === "Add Camera" ||
      currentButton === "Add User" ||
      currentButton === "Add Edge Device" ||
      currentButton === "Add Meter Configuration" ? (
        <Col md="auto" className="pt-3 pl-0">
          {currentButton === "Add Edge Device" || currentButton === "Add User" || currentButton === "Add Meter Configuration" ? (
            <Button className="btn-blue-bg" onClick={addClicked}>
              {currentButton}
            </Button>
          ) : (
            <Button className="btn-blue-bg" onClick={() => addActionRef.current.click()}>
              {currentButton}
            </Button>
          )}
        </Col>
      ) : null}

      <Col md="auto" className="d-flex justify-content-end pt-3 pr-0">
        <div style={{ display: "flex" }}>
          <Button
            style={{ marginRight: "0.5rem" }}
            className="btn-fill"
            variant="danger"
            onClick={() => {
              history.push({
                pathname: previousLocation,
                search: property_id,
              });
            }}
            disabled={disable}
          >
            Back
          </Button>
          <div
            onClick={() => {
              if (previousLocation === "/printed-citation-setup" && !isThereVisitorLot) {
                handleAlertOnVisitorLot();
              } else if (previousLocation === "/quick-permit-setup") {
                history.push({
                  pathname: "/landingpage",
                });
              } else {
                history.push({
                  pathname: nextLocation,
                  search: property_id,
                });
              }
            }}
          >
            <Button
              variant={previousLocation === "/quick-permit-setup" ? "success" : "info"}
              disabled={nextButtonDisablity}
              onClick={currentButton === "Permission" && addClicked}
              // disabled={
              //   previousLocation === "/settings"
              //     ? isNextButtonDisable
              //     : !property_id.length && true
              // }
              className="btn-fill"
            >
              {previousLocation === "/quick-permit-setup" ? "Finish" : "Next"}
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

/* To covert date according to property timezone */

export const tzDate = (timezone, date) => {
  const dateToBePassed = date || "";
  const moment = require("moment-timezone");
  return moment(dateToBePassed).tz(timezone)?.format("lll");
};

/* ---------------- Creating ZPL file and Printing it to Zebra ---------------- */

export const generateZplFile = async (ticketData, encodedImage) => {
  let stateAbbreviation = "";
  const filteredState = allStates.filter((item) => item.value === ticketData.state_name);
  if (filteredState?.length) {
    stateAbbreviation = filteredState[0].label;
  } else {
    stateAbbreviation = ticketData.state_name;
  }
  const plate = `${ticketData.lpn} ${ticketData.country_name || ""} ${stateAbbreviation || ""}`;
  const enforcer = ticketData?.user?.last_name || "-";
  let date = ticketData.created_at ? momentTimeZone.tz(ticketData.created_at, "UTC").tz(ticketData.property.timezone).format("lll") : "-";

  date = getDateInFrenchLanguage(date, ticketData.language);
  const carColor = getColorInFrenchLanguage(ticketData.car_color, ticketData.language);
  let note = ticketData.notes_external ? ticketData.notes_external : "-";
  const feeStructure = [];
  if (ticketData.property.reasons) {
    ticketData.reasons.forEach((rs) => {
      const currentReason = ticketData.property.reasons.find((s) => s.id === rs.id);
      if (currentReason.reason_costs) {
        const sortedCost = currentReason.reason_costs.sort((a, b) => (a.period_in_days > b.period_in_days ? 1 : -1));
        feeStructure.push({
          title: currentReason.title,
          fees: sortedCost,
        });
      }
    });
  }
  let feesString = "";
  let reasonLength = 0;
  if (ticketData.language === "fn") {
    feeStructure.forEach((s) => {
      if (s.title) {
        const reasonTitle = s.title;
        if (reasonTitle.length > 52) {
          reasonLength = reasonLength + 1;
        }
      }
      if (s.fees && s.fees.length > 0) {
        s.fees.sort((a, b) => (Number(a.cost) > Number(b.cost) ? -1 : 1));

        const index = s.fees.length - 1;
        let i = 0;
        let fee = s.fees[0].cost;
        let updatedFee = fee.replace(".", ",");
        if (index > 0) {
          // eslint-disable-next-line
          feesString += `${s.title} \\&${updatedFee}$ \\&`;
          for (i = 1; i < index; i++) {
            fee = s.fees[i].cost;
            updatedFee = fee.replace(".", ",");
            // eslint-disable-next-line
            feesString += `${updatedFee}$ si payé entre ${s.fees[i + 1].period_in_days + 1} - ${s.fees[i].period_in_days} jours \\&`;
          }
          fee = s.fees[index].cost;
          updatedFee = fee.replace(".", ",");
          // eslint-disable-next-line
          feesString += `${updatedFee}$ si payé dans les ${s.fees[index].period_in_days} jours \\&`;
        } else {
          // eslint-disable-next-line
          feesString += `${s.title} \\&${updatedFee}$ \\&`;
        }
      } else {
        // eslint-disable-next-line
        feesString += `${s.title} \\&`;
      }
    });
  } else {
    feeStructure.forEach((s) => {
      if (s.title) {
        const reasonTitle = s.title;
        if (reasonTitle.length > 52) {
          reasonLength = reasonLength + 1;
        }
      }
      if (s.fees && s.fees.length > 0) {
        s.fees.sort((a, b) => (Number(a.cost) > Number(b.cost) ? -1 : 1));

        const index = s.fees.length - 1;
        let i = 0;
        if (index > 0) {
          // eslint-disable-next-line
          feesString += `${s.title} \\&$${s.fees[0].cost} \\&`;
          for (i = 1; i < index; i++) {
            // eslint-disable-next-line
            feesString += `$${s.fees[i].cost} if paid between ${s.fees[i + 1].period_in_days + 1} - ${s.fees[i].period_in_days} days \\&`;
          }
          // eslint-disable-next-line
          feesString += `$${s.fees[index].cost} if paid within ${s.fees[index].period_in_days} days \\&`;
        } else {
          // eslint-disable-next-line
          feesString += `${s.title} \\&$${s.fees[index].cost} \\&`;
        }
      } else {
        // eslint-disable-next-line
        feesString += `${s.title} \\&`;
      }
    });
  }
  const ticketAddress = ticketData.property.address;
  const zone = ticketData.property.domain || ticketData.property.subdomain;
  const payUrl = getPaymentUrl(ticketData);

  const lineSpacing = 32;
  const valueForCitation = 138;
  const valueForDate = valueForCitation + lineSpacing; // 170
  const valueForPlate = valueForDate + lineSpacing; // 202
  let valueForVin = valueForPlate;
  if (!ticketData.vin_visibility) {
    valueForVin = valueForVin + lineSpacing; // 234
  }
  const valueForCarMake = valueForVin + lineSpacing; // 234, 266
  const valueForCarModel = valueForCarMake + lineSpacing; // 266, 298
  const valueForCarColor = valueForCarModel + lineSpacing; // 298, 330
  const valueForCarEnforcer = valueForCarColor + lineSpacing; // 330, 362
  const valueForCarAddress = valueForCarEnforcer + lineSpacing; // 362, 394
  let valueForZone = valueForCarAddress + lineSpacing; // 394, 426
  if (ticketAddress.length > 40) {
    valueForZone = valueForZone + lineSpacing; // 426, 458
  }
  const valueForVio = valueForZone + lineSpacing; // 458, 490

  const check_number = (feesString.match(/\\&/g) || []).length;
  let valueForNote = valueForVio + lineSpacing * check_number + 5;

  if (check_number > 4) {
    valueForNote = valueForNote + 8;
  }
  if (reasonLength === 1) {
    valueForNote = valueForNote + lineSpacing;
  }
  if (reasonLength === 2) {
    valueForNote = valueForNote + 2 * lineSpacing;
  }
  let noteLineDifference = lineSpacing;
  const chr = "\\&";
  if (note.length > 40 && note.length < 81) {
    note = note.substring(0, 40) + chr + note.substring(40, note.length);
    noteLineDifference = 2 * lineSpacing;
  } else if (note.length > 80) {
    const notefirst = note.substring(0, 40) + chr + note.substring(40, note.length);
    note = notefirst.substring(0, 82) + chr + notefirst.substring(82, notefirst.length);
    noteLineDifference = 3 * lineSpacing;
  }

  const valueForPayment = valueForNote + noteLineDifference + 5;
  const valueForQrCode = valueForPayment + lineSpacing;
  const valueForImage = valueForQrCode + 10;
  const valueForFooter = valueForImage + 250;

  // Header Text
  let textForCitation = "";
  let textForDate = "";
  let textForPlate = "";
  let textForVin = "";
  let textForMake = "";
  let textForModel = "";
  let textForColor = "";
  let textForEnforcer = "";
  let textForAddress = "";
  let textForZone = "";
  let textForViolation = "";
  let textForNote = "";
  let textForPayment = "";
  let textForQRCode = "";

  if (ticketData.language === "fn") {
    textForCitation = "Num.Réclamation";
    textForDate = "Date";
    textForPlate = "Immatriculation";
    textForVin = "NSV";
    textForMake = "Marque";
    textForModel = "Modele";
    textForColor = "Couleur";
    textForEnforcer = "Agent";
    textForAddress = "Adresse";
    textForZone = "Zone";
    textForViolation = "Violation";
    textForNote = "Note";
    textForPayment = "Paiement";
    textForQRCode = "Qr Code";
  } else {
    textForCitation = "Citation No";
    textForDate = "Date";
    textForPlate = "Plate";
    textForVin = "VIN";
    textForMake = "Car Make";
    textForModel = "Car Model";
    textForColor = "Car Color";
    textForEnforcer = "Enforcer";
    textForAddress = "Address";
    textForZone = "Zone";
    textForViolation = "Violation";
    textForNote = "Note";
    textForPayment = "Payment Url";
    textForQRCode = "Qr Code";
  }

  // ZPL DATA BEGIN
  let data = "^XA";
  data += "^CI28";
  // CITATION HEADERS
  data += "^POI";
  data += `^FO40,${valueForCitation}^A0,28^FB230,1,10,L,0^FD${textForCitation}^FS`;
  data += `^FO40,${valueForDate}^A0,28^FB230,1,10,L,0^FD${textForDate}^FS`;
  data += `^FO40,${valueForPlate}^A0,28^FB230,1,10,L,0^FD${textForPlate}^FS`;
  if (!ticketData.vin_visibility) {
    data += `^FO40,${valueForVin}^A0,28^FB230,1,10,L,0^FD${textForVin}^FS`;
  }
  data += `^FO40,${valueForCarMake}^A0,28^FB230,1,10,L,0^FD${textForMake}^FS`;
  data += `^FO40,${valueForCarModel}^A0,28^FB230,1,10,L,0^FD${textForModel}^FS`;
  data += `^FO40,${valueForCarColor}^A0,28^FB230,1,10,L,0^FD${textForColor}^FS`;
  data += `^FO40,${valueForCarEnforcer}^A0,28^FB230,1,10,L,0^FD${textForEnforcer}^FS`;
  data += `^FO40,${valueForCarAddress}^A0,28^FB230,2,10,L,0^FD${textForAddress}^FS`;
  data += `^FO40,${valueForZone}^A0,28^FB230,1,10,L,0^FD${textForZone}^FS`;
  data += `^FO40,${valueForVio}^A0,28^FB230,10,10,L,0^FD${textForViolation}^FS`;
  data += `^FO40,${valueForNote}^A0,28^FB230,3,10,L,0^FD${textForNote}^FS`;
  data += `^FO40,${valueForPayment}^A0,28^FB230,1,10,L,0^FD${textForPayment}^FS`;
  data += `^FO40,${valueForQrCode}^A0,28^FB230,4,10,L,0^FD${textForQRCode}^FS`;

  // CITATION DATA
  if (ticketData.citation_footer !== "null" && typeof ticketData.citation_footer !== "object" && ticketData.citation_footer !== null) {
    data += `^FO40,${valueForFooter}^A0,24^FB690,6,10,L,0,^FD${ticketData.citation_footer}^FS`;
  }

  data += `^FO242,${valueForCitation}^A0,24^FB560,1,10,L,0^FD${ticketData.ticket_no}^FS`;
  data += `^FO242,${valueForDate}^A0,24^FB560,1,10,L,0^FD ${date}^FS`;
  data += `^FO242,${valueForPlate}^A0,24^FB560,1,10,L,0^FD${plate}^FS`;
  if (!ticketData.vin_visibility) {
    data += `^FO242,${valueForVin}^A0,24^FB560,1,10,L,0^FD${ticketData.vin}^FS`;
  }
  data += `^FO242,${valueForCarMake}^A0,24^FB560,1,10,L,0^FD${ticketData.car_make}^FS`;
  data += `^FO242,${valueForCarModel}^A0,24^FB560,1,10,L,0^FD${ticketData.car_model}^FS`;
  data += `^FO242,${valueForCarColor}^A0,24^FB560,1,10,L,0^FD${carColor}^FS`;
  data += `^FO242,${valueForCarEnforcer}^A0,24^FB560,1,10,L,0^FD${enforcer}^FS`;
  data += `^FO242,${valueForCarAddress}^A0,24^FB560,2,10,L,0^FD${ticketAddress}^FS`;
  data += `^FO242,${valueForZone}^A0,24^FB560,1,10,L,0^FD${zone}^FS`;
  data += `^FO242,${valueForVio}^A0,24^FB560,10,10,L,0$^FD${feesString}^FS`;
  data += `^FO242,${valueForNote}^A0,24^FB560,3,10,L,0^FD${note}^FS`;
  data += `^FO242,${valueForPayment}^A0,24^FB560,1,10,L,0^FD${payUrl}^FS`;
  data += `^FO242,${valueForQrCode}^FD^BQN,2,6^FDQA,${payUrl}^FS`;
  data += `^FO490,${valueForImage}^A0,24^FB560,1,10,L,0^FD^GFA,${encodedImage}^FS`;

  data += `^XZ`;
  // ZPL DATA END
  return data;
};

export const sendFileToZebra = (selected_device, fileUrl) => {
  return selected_device.sendFile(fileUrl, undefined, errorCallback);
};

const getPaymentUrl = (ticket) => {
  const domain = ticket?.property.domain;
  const subdomain = ticket?.property.subdomain || "-";
  const plate = `${ticket.lpn} ${ticket.country_name || ""} ${ticket.state_name || ""}`;
  const { ticket_no: id } = ticket;
  const plateCharacters = plate.substring(0, 3);
  const url = `${plateCharacters}-${id}`;

  let paymentUrl;
  const windowUrl = window?.location?.href?.split("/");
  const baseUrl = windowUrl[0];
  if (baseUrl === "https:") {
    const newurl = windowUrl[2].split(".");
    if (newurl[2] === "dev") {
      if (ticket.property.property_id === "d472ebed-942f-49a3-99b3-b1bd2efba5ff") {
        paymentUrl = `https://statpark.${process.env.REACT_APP_DRIVER_URL}/td/${url}`;
      } else {
        paymentUrl = `https://${domain || subdomain}.${process.env.REACT_APP_DRIVER_URL}/td/${url}`;
      }
    } else {
      if (ticket.property.property_id === "2d4ab914-4e95-4ce4-8eff-57988f6acaa3") {
        paymentUrl = `https://statpark.${process.env.REACT_APP_DRIVER_URL}/td/${url}`;
      } else {
        paymentUrl = `https://${domain || subdomain}.${process.env.REACT_APP_DRIVER_URL}/td/${url}`;
      }
    }
  } else {
    paymentUrl = `${process.env.REACT_APP_PAYMENT_URL}/td/${url}`;
  }
  return paymentUrl;
};

const errorCallback = (errorMessage) => {
  console.log("in error callback");
  alert("Error: " + errorMessage);
};

export const getBlobUrl = (zplData, type) => {
  let fileType = "file/zpl";
  if (type === "png") {
    fileType = "file/png";
  } else if (type === "pdf") {
    fileType = "file/pdf";
  }
  const blob = new Blob([zplData], { type: fileType });
  return window.URL.createObjectURL(blob);
};

/* ---------------- Creating ZPL file and Printing it to Zebra ---------------- */

export const utcToSpecificTimeZoneDate = (timezone, date, time) => {
  try {
    if (time) {
      return momentTimeZone.tz(date, "UTC").tz(timezone).format("YYYY-MM-DD");
    } else {
      return momentTimeZone.tz(date, "UTC").tz(timezone).format("lll");
    }
  } catch (e) {
    console.log("ERROR:: in utcToSpecificTimeZone", e);
  }
};

export const utcToSpecificTimeZone = (timezone, date, time) => {
  try {
    if (time) {
      return momentTimeZone.tz(date, "UTC").tz(timezone).format("YYYY-MM-DD HH:mm");
    } else {
      return momentTimeZone.tz(date, "UTC").tz(timezone).format("lll");
    }
  } catch (e) {
    console.log("ERROR:: in utcToSpecificTimeZone", e);
  }
};

export const convertPropertyTimeToUtc = (timezone, startDate, endDate, withTime) => {
  try {
    let startDateWithTime = "";
    let endDateWithTime = "";
    if (withTime) {
      startDateWithTime = moment(startDate).format("YYYY-MM-DD HH:mm:SS");
      endDateWithTime = moment(endDate).format("YYYY-MM-DD HH:mm:SS");
    } else {
      const formatStartDate = moment(startDate).format("YYYY-MM-DD");
      startDateWithTime = `${formatStartDate} 00:00:00`;

      const formatEndDate = moment(endDate).format("YYYY-MM-DD");
      endDateWithTime = `${formatEndDate} 23:59:59`;
    }
    const convertedStartTime = moment.tz(startDateWithTime, timezone).format();
    const startDateInUtc = moment.tz(convertedStartTime, "UTC").format("YYYY-MM-DD HH:mm:SS");
    const convertedEndTime = moment.tz(endDateWithTime, timezone).format();
    const endDateInUtc = moment.tz(convertedEndTime, "UTC").format("YYYY-MM-DD HH:mm:SS");
    return { startDateInUtc, endDateInUtc };
  } catch (e) {
    console.log("ERROR:: in utcToSpecificTimeZone", e);
  }
};

export const convertDateTimeToUtc = (timezone, startDate, endDate) => {
  try {
    const begin_time = startDate;
    const expire_time = endDate;
    const beginTimeFormat = moment(begin_time).format("YYYY-MM-DD HH:mm:ss");
    const expiryTimeFormat = moment(expire_time).format("YYYY-MM-DD HH:mm:ss");

    const convertedBeginTime = moment.tz(beginTimeFormat, timezone).format();
    const convertedExpiryTime = moment.tz(expiryTimeFormat, timezone).format();
    const startcurrent_time = moment.tz(convertedBeginTime, "UTC").format();
    const endcurrent_time = moment.tz(convertedExpiryTime, "UTC").format();
    return {
      startcurrent_time,
      endcurrent_time,
    };
  } catch (e) {
    console.error("ERROR:: in utcToSpecificTimeZone", e);
  }
};

export const AddTimeInDate = (startDate, endDate, currentStartTime, currentEndTIme) => {
  try {
    const timeStartFormat = moment(startDate).format("YYYY-MM-DD");
    let startTimeFormat = `${timeStartFormat} 00:00:00`;
    const timeEndFormat = moment(endDate).format("YYYY-MM-DD");
    let EndTimeFormat = `${timeEndFormat} 23:59:59`;
    if (currentStartTime && currentEndTIme) {
      const hours = currentStartTime.getHours();
      const minutes = currentStartTime.getMinutes();
      const seconds = currentStartTime.getSeconds();
      const endHours = currentEndTIme.getHours();
      const endMinutes = currentEndTIme.getMinutes();
      const endSeconds = 59 - currentEndTIme.getSeconds();
      // Format the time components with leading zeros if needed
      const formattedStartTime = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
      const formattedEndTime = `${String(endHours).padStart(2, "0")}:${String(endMinutes).padStart(2, "0")}:${String(endSeconds).padStart(2, "0")}`;
      startTimeFormat = `${timeStartFormat} ${formattedStartTime}`;
      EndTimeFormat = `${timeStartFormat} ${formattedEndTime}`;
    }

    return {
      startTimeFormat,
      EndTimeFormat,
    };
  } catch (e) {
    console.error("ERROR:: time add in date", e);
  }
};

const checkPasswordLength = (password) => {
  return password.length >= 8;
};

// Check for special characters
const checkSpecialCharacters = (password) => {
  const pattern = /[!@#$%^&*()+_\-=}{[\]|:;"/?.><,`~]/;
  return pattern.test(password);
};

// Check for an uppercase character
const checkUppercase = (password) => {
  const pattern = /[A-Z]/;
  return pattern.test(password);
};

const checkLowercase = (password) => {
  const pattern = /[a-z]/;
  return pattern.test(password);
};

// Check for a number
const checkNumber = (password) => {
  const pattern = /\d/;
  return pattern.test(password);
};

export const PasswordValidation = (password) => {
  let lengthValue = false;
  let special = false;
  let uppercase = false;
  let lowercase = false;
  let number = false;

  lengthValue = checkPasswordLength(password);
  special = checkSpecialCharacters(password);
  uppercase = checkUppercase(password);
  lowercase = checkLowercase(password);
  number = checkNumber(password);

  if (lengthValue && special && uppercase && lowercase && number) {
    return "";
  }
  return `Password Requirement  </br>
      <i ${lengthValue ? `class="fa fa-check-circle"` : `class="fa fa-times"`} aria-hidden="true" style=${lengthValue ? "color:green" : "color:red"}></i>
    minimum 8 characters </br>

    <i ${uppercase ? `class="fa fa-check-circle"` : `class="fa fa-times"`} aria-hidden="true" style=${uppercase ? "color:green" : "color:red"}></i>
    one uppercase </br>

    <i ${lowercase ? `class="fa fa-check-circle"` : `class="fa fa-times"`} aria-hidden="true" style=${lowercase ? "color:green" : "color:red"}></i>
    one lowercase </br>

    <i ${number ? `class="fa fa-check-circle"` : `class="fa fa-times"`} aria-hidden="true" style=${number ? "color:green" : "color:red"}></i>
    one number </br>

    <i ${special ? `class="fa fa-check-circle"` : `class="fa fa-times"`} aria-hidden="true" style=${special ? "color:green" : "color:red"}></i>
    one special character </br>`;
};

export const removeAccent = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const removeSpecialChar = (data) => {
  return data?.replace(/[^a-zA-Z0-9 ]/g, "");
};

export const secondAndMinute = (expiration_time) => {
  const milisecond__diff = new Date(expiration_time).getTime();
  const cur_time = new Date().getTime();
  const second_remaining = Math.floor((milisecond__diff - cur_time) / 1000);
  const minutes_remaining = Math.floor(second_remaining / 60);
  const final_remain_second = second_remaining % 60;
  return { minutes: minutes_remaining, seconds: final_remain_second };
};

export const scroolToTop = () => {
  try {
    const tableElement = document.getElementsByClassName("rdt_Table")[0];
    tableElement?.scrollIntoView({ behavior: "smooth" });
  } catch (error) {
    console.error("scroolToTop:::", e);
  }
};

export const getTodayDate = () => {
  const today = new Date();
  return moment(today).format("DD MMMM YYYY");
};

export const isSubdomain = (url) => {
  url = url.replace(/^\s+/, "");
  url = url.replace(/\s+$/, "");
  url = url.replace(/\\/g, "/");
  url = url.replace(/^http:\/\/|^https:\/\/|^ftp:\/\//i, "");
  url = url.replace(/^www\./i, "");
  url = url.replace(/\/(.*)/, "");
  let domain = "";
  let TLD = "";
  if (url.match(/\.[a-z]{2,4}$/i)) {
    url = url.replace(/\.[a-z]{2,4}$/i, "");
    domain = url.split(".")[0];
    TLD = url.split(".")[url.split(".").length - 1];
  }

  const isOtherDomainExist = domain !== "enforcement";
  return { isOtherDomainExist, domain, TLD };
};

export const phoneValidation = (rowData) => {
  if (Object.keys(rowData).length !== 0 && "phone" in rowData) {
    if (rowData?.phone === "") {
      return "Phone number is required!";
    } else if (rowData?.phone?.length >= 11 || rowData?.phone?.length < 10) {
      return "Please  enter valid Phone Number";
    } else if (!/^\d+$/.test(rowData.phone)) {
      return "Please  enter valid Phone Number";
    }
  }
  return "";
};

export const validateCardNumber = (number) => {
  // Check if the number contains only numeric value
  // and is of between 13 to 19 digits
  const regex = /^\d{13,19}$/;
  if (!regex.test(number)) {
    return false;
  }
  return numberCheck(number);
};

const numberCheck = (val) => {
  let checksum = 0; // running checksum total
  let j = 1; // takes value of 1 or 2
  // Process each digit one by one starting from the last
  for (let i = val.length - 1; i >= 0; i--) {
    let calc = 0;
    // Extract the next digit and multiply by 1 or 2 on alternative digits.
    calc = Number(val.charAt(i)) * j;
    // If the result is in two digits add 1 to the checksum total
    if (calc > 9) {
      checksum = checksum + 1;
      calc = calc - 10;
    }
    // Add the units element to the checksum total
    checksum = checksum + calc;
    // Switch the value of j
    if (j === 1) {
      j = 2;
    } else {
      j = 1;
    }
  }
  // Check if it is divisible by 10 or not.
  return checksum % 10 === 0;
};

export const getDomain = () => {
  let domain = "";
  if (process.env.REACT_APP_MAIN_URL.includes("localhost")) {
    domain = "aparna";
  } else {
    const url = window.location.href;
    const domainDetails = isSubdomain(url);
    domain = domainDetails.domain;
  }
  return domain;
};

export default CustomContentAlert;
