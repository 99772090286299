import React, { Component } from "react";
import { Card, Row, Col, Button, Form, Container, Tooltip, OverlayTrigger } from "react-bootstrap";
import isEqual from "lodash/isEqual";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { SuperAdminAction, ReportsAction } from "../../Actions";
import Loader from "../Loader/Loader";
import { tableHeightScrollBars, findBySearch, utcToSpecificTimeZone, scroolToTop, AddTimeInDate } from "../helper";
import "react-datepicker/dist/react-datepicker.css";
import PropertDetails from "../LandingPage/PropertySelection";
import { saveAs } from "file-saver";
import moment from "moment";
import "react-image-lightbox/style.css";
import DateRangeAndAmount from "../DateRangeAndAmount/DateRangeAndAmount";

class AccessLogReport extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 3);
    this.state = {
      PropertyId: this.props.PropertyId,
      accessLogList: [],
      loader: false,
      searchValue: "",
      count: 0,
      timezone: this.props.timezone,
      property: this.props.property,
      selectedProperty: "",
      selectedPropertyObj: {},
      all_properties: [],
      childcheckbox: true,
      AccessLogReportLots: [],
      lot_id: "",
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      startDateTime: moment(new Date(datebeforeThirtyDays)).format("YYYY-MM-DD HH:mm:ss"),
      endDateTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      currentSummaryDate: "",
      finalTableHeight: 0,
      maxEndDate: new Date(),
    };

    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    this.columns = [
      {
        name: "Name",
        width: "120px",
        sortable: true,
        cell: (row) => this.showFields(row.name),
        selector: (row) => row?.name,
      },
      {
        name: "Card #",
        width: "140px",
        sortable: true,
        cell: (row) => this.showFields(row.card_number),
        selector: (row) => row?.card_number,
      },
      {
        name: "License Plate #",
        width: "140px",
        sortable: true,
        cell: (row) => this.showFields(row.lpn),
        selector: (row) => row?.lpn,
      },
      {
        name: "Source",
        width: "140px",
        sortable: true,
        cell: (row) => this.showFields(row.source),
        selector: (row) => row?.source,
      },
      {
        name: "Lane Name",
        width: "140px",
        sortable: true,
        cell: (row) => this.showFields(row.lane_name),
        selector: (row) => row?.lane_name,
      },
      {
        name: "Permit Class",
        width: "140px",
        sortable: true,
        cell: (row) => this.showFields(row.permit_class),
        selector: (row) => row?.permit_class,
      },
      {
        name: "Access Granted",
        width: "140px",
        sortable: true,
        cell: (row) => this.showFields(row.access_granted),
        selector: (row) => row?.access_granted,
      },
      {
        name: "Entry Time",
        width: "160px",
        sortable: true,
        cell: (row) => this.showTimeField(row.entry_time),
        selector: (row) => row?.entry_time,
      },
      {
        name: "Exit Time",
        width: "160px",
        sortable: true,
        cell: (row) => this.showTimeField(row.exit_time),
        selector: (row) => row?.exit_time,
      },
    ];
  }

  showFields = (row) => (
    <>
      {row ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showTimeField = (row) => (
    <>
      {row != '-' ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement="top"
          overlay={<Tooltip id="tooltip-qw">{utcToSpecificTimeZone(this.props.timezone, row, true)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">{utcToSpecificTimeZone(this.props.timezone, row, true)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  handleCheckBox = () => {
    this.setState({ childcheckbox: !this.state.childcheckbox });
  };

  componentDidMount = async () => {
    const { startTimeFormat, EndTimeFormat } = AddTimeInDate(this.state.startDate, this.state.endDate);
    await this.getAccessLogs();
    await this.props.get_all_properties();
    const { PropertyId } = this.state;
    await this.props.get_all_lots(PropertyId);
    const current_lot = this.props.lots;
    this.setState(
      {
        all_properties: this.props?.getAllProperties,
        AccessLogReportLots: current_lot,
        startDateTime: startTimeFormat,
        endDateTime: EndTimeFormat,
      },
      async () => {
        const selectedPropertyObj = this.state.all_properties.find((item) => item.id === PropertyId);
        if (selectedPropertyObj) {
          this.setState({
            selectedPropertyObj: {
              label: selectedPropertyObj.site_name,
              value: selectedPropertyObj.id,
            },
            selectedProperty: PropertyId,
            selectedDomainName: selectedPropertyObj.site_name,
          });
        }
      },
    );

    const exportUrl = process.env.REACT_APP_CONFIG_BASE_URL;
    if (exportUrl) {
      this.setState({
        exportUrl: `${exportUrl}/api/v1/revenue_report/export/${this.state?.property?.id}`,
        exportPdfUrl: `${exportUrl}/api/v1/revenue_report/exportPdf`,
      });
    }
    const height = tableHeightScrollBars();
    this.setState({
      finalTableHeight: height,
    });
  };

  getAccessLogs = async () => {
    this.setState({ loader: true }, async () => {
      const { PropertyId, childcheckbox, timezone, startDate, endDate } = this.state;
      const pageobj = {
        PropertyId,
        timezone,
        childcheckbox,
      };
      if (startDate) {
        pageobj.startDate = startDate;
      }
      if (endDate) {
        pageobj.endDate = endDate;
      }
      await this.props.getReportList(pageobj);
      const selectedPropertyName = this.state.property?.property_name;
      const selectedDomainName = this.state.property?.domain;
      await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      this.setState({
        accessLogList: this.props?.accessLogList?.rows?.length ? JSON.parse(JSON.stringify(this.props.accessLogList.rows)) : [],
        count: this.props.accessLogList.count,
      });
      this.setState({ loader: false });
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (!isEqual(prevProps.accessLogList, this.props.accessLogList)) {
      this.setState({
        accessLogList: JSON.parse(JSON.stringify(this.state.accessLogList)),
        pageChanged: true,
        count: this.props.accessLogList.count,
      });
    }
    if (!isEqual(prevProps.lots, this.props.lots)) {
      this.setState({
        AccessLogReportLots: this.props.lots,
      });
    }
  };

  dataTableHandle = (accessLogList) => {
    return (
      <DataTable
        data={this.state.searchValue.length ? this.state.searchedaccessLogList : accessLogList}
        columns={this.columns}
        customStyles={this.customStyles}
        responsive={true}
        defaultSortAsc={true}
        onChangePage={scroolToTop}
        fixedHeader={true}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  handleChangeLot = ({ target }) => {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 3);
    this.setState({
      [target.name]: target.value,
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      currentSummaryDate: null,
    });
  };

  handleCurrentDate = (date) => {
    this.setState({
      currentSummaryDate: date,
      startDate: null,
      endDate: null,
    });
  };

  handleChangeStartingDates = (date) => {
    if (date) {
      const end = new Date(date);
      end.setDate(date.getDate() + 7);
      let endDate = new Date();
      if (new Date() >= end) {
        endDate = end;
      }
      this.setState({
        startDate: date,
        endDate: endDate,
        maxEndDate: new Date(endDate),
        currentSummaryDate: null,
      });
    }
  };

  handleChangeEndDates = (date) => {
    if (date) {
      this.setState({
        endDate: date,
        currentSummaryDate: null,
      });
    }
  };

  handleSearch = () => {
    this.setState({ loader: true }, async () => {
      const { lot_id, childcheckbox, currentSummaryDate, startDate, endDate, selectedPropertyObj, PropertyId, timezone } = this.state;
      const pageobj = {
        childcheckbox,
        PropertyId,
        timezone,
      };
      let start, end;
      if (Object.keys(selectedPropertyObj).length) {
        pageobj.PropertyId = selectedPropertyObj.value;
      } else {
        pageobj.PropertyId = PropertyId;
      }
      if (lot_id) {
        pageobj.lot_id = lot_id;
      }
      if (currentSummaryDate) {
        pageobj.currentSummaryDate = currentSummaryDate;
        const { startTimeFormat, EndTimeFormat } = AddTimeInDate(currentSummaryDate, currentSummaryDate);
        start = startTimeFormat;
        end = EndTimeFormat;
      }
      if (startDate && endDate) {
        pageobj.startDate = startDate;
        pageobj.endDate = endDate;
        const { startTimeFormat, EndTimeFormat } = AddTimeInDate(startDate, endDate);
        start = startTimeFormat;
        end = EndTimeFormat;
      }
      await this.props.getReportList(pageobj);
      console.log(this.state.accessLogList);
      this.setState({
        accessLogList: JSON.parse(JSON.stringify(this.props.accessLogList.rows)),
        count: this.props.accessLogList.count,
        startDateTime: start,
        endDateTime: end,
      });
      this.setState({ loader: false });
      document.body.classList.remove("foc-open");
    });
  };

  handleSearchChange = async ({ target }) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();
    this.setState({ searchValue: val }, async () => {
      const columnList = ["name", "tag", "lpn", "source", "permit_usage", "lane_name", "permit_class", "entry_time", "exit_time"];
      if (this.state.searchValue.length) {
        this.setState(
          {
            searchedaccessLogList: findBySearch(this.state.searchValue, this.state.accessLogList, this.state.accessLogList, columnList, this.props.timezone),
          },
          () => {
            this.setState({ count: this.state.searchedaccessLogList.length });
          },
        );
      } else {
        this.setState({
          count: this.props.accessLogList.count,
          searchedaccessLogList: [],
          accessLogList: this.props.accessLogList.rows,
        });
      }
    });
  };

  resetAll = async () => {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 3);
    const { startTimeFormat, EndTimeFormat } = AddTimeInDate(new Date(datebeforeThirtyDays), new Date());
    this.setState({
      selectedRadio: null,
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      startDateTime: startTimeFormat,
      endDateTime: EndTimeFormat,
      currentSummaryDate: null,
      selectedPropertyObj: {},
      lot_id: "",
      childcheckbox: true,
      // exportButtonDisable: true,
    });
    const { page, Norow, selectedColumn, sort_direction, PropertyId, startDate, endDate, selectedDomainName, timezone } = this.state;
    const pageobj = {
      page,
      Norow,
      selectedColumn,
      sort_direction,
      PropertyId,
      timezone,
    };
    if (startDate) {
      pageobj.startDate = new Date(datebeforeThirtyDays);
    }
    if (endDate) {
      pageobj.endDate = new Date();
    }
    await this.props.getReportList(pageobj);
    this.setState({
      pageChanged: true,
      page: 1,
      accessLogList: this.props.accessLogList.rows,
      count: this.props.accessLogList.count,
      selectedPropertyObj: {
        label: selectedDomainName,
        value: PropertyId,
      },
    });
    setTimeout(() => {
      this.setState({ findMore: false });
    }, 2000);
    this.setState({ loader: false });
    document.body.classList.remove("foc-open");
  };

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  handleExport = async () => {
    const {
      page,
      Norow,
      selectedColumn,
      sort_direction,
      lot_id,
      childcheckbox,
      currentSummaryDate,
      startDate,
      endDate,
      selectedPropertyObj,
      PropertyId,
      selectedDomainName,
      timezone,
    } = this.state;
    const pageobj = {
      page,
      Norow,
      selectedColumn,
      sort_direction,
      childcheckbox,
      PropertyId,
      timezone,
      exportValue: true,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.PropertyId = selectedPropertyObj.value;
    } else {
      pageobj.PropertyId = PropertyId;
    }
    if (lot_id) {
      pageobj.lot_id = lot_id;
    }
    if (currentSummaryDate) {
      pageobj.currentSummaryDate = currentSummaryDate;
    }
    if (startDate && endDate) {
      pageobj.startDate = startDate;
      pageobj.endDate = endDate;
    }
    const bufferData = await this.props.getReportList(pageobj);
    const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
    const exportDataName = `Access_Log-${currentTimeDate}-${selectedDomainName}.xlsx`;
    if (bufferData) {
      saveAs(
        new Blob([bufferData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        exportDataName,
      );
    }
  };

  changeProperty(event) {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 3);
    this.setState(
      {
        selectedProperty: event.value,
        selectedPropertyObj: event,
        startDate: new Date(datebeforeThirtyDays),
        endDate: new Date(),
        currentSummaryDate: null,
      },
      () => {
        const selectedPropertyObj = this.state.all_properties.find((item) => item.id === this.state.selectedProperty);

        this.props.selected_Property(selectedPropertyObj);
        this.props.get_all_lots(event.value);
        const current_lot = this.props.lots;
        this.setState({
          AccessLogReportLots: current_lot,
        });
        this.setState({
          isFormValid: true,
          pageDropdownDisabled: false,
        });
      },
    );
  }

  render() {
    const {
      accessLogList,
      loader,
      childcheckbox,
      AccessLogReportLots,
      lot_id,
      startDate,
      endDate,
      currentSummaryDate,
      searchValue,
      maxEndDate,
      startDateTime,
      endDateTime,
    } = this.state;

    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="mb-0">
                <Card.Body className="pos-rel-overflow-hide">
                  <>
                    <div className="sidebarFilter">
                      <Card className="contact-container mb-0">
                        <Card.Header style={{ backgroundColor: this.props.main_body }}>
                          <div className="d-flex align-items-center justify-content-between">
                            <Card.Title as="h4">Permit Log Report</Card.Title>
                            <a
                              onClick={this.hideSlider}
                              href="javascript:void(0);"
                              className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </a>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <Row>
                            <Col md="12">
                              <div className="mb-2">
                                <label className="fancy-checkbox">
                                  <input
                                    type="checkbox"
                                    name={"childcheckbox"}
                                    checked={childcheckbox}
                                    onChange={this.handleCheckBox}
                                    className="custom-control-input"
                                    id="childcheckbox1"
                                  ></input>
                                  <span>
                                    <i></i>Include Children Sites
                                  </span>
                                </label>
                              </div>
                            </Col>
                            <Col md="12">
                              <PropertDetails selectedPropertyObj={this.state.selectedPropertyObj} changeProperty={this.changeProperty.bind(this)} />
                            </Col>
                            <Col md="12">
                              <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>LOTS:</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="lot_id"
                                  value={lot_id}
                                  onChange={this.handleChangeLot}
                                  disabled={AccessLogReportLots.length < 1}
                                  placeholder="Select Lot"
                                >
                                  {AccessLogReportLots?.length ? (
                                    <>
                                      <option value="">Any</option>
                                      {AccessLogReportLots.map((item, index) => (
                                        <option key={index} value={item.id}>
                                          {item.alias}
                                        </option>
                                      ))}
                                    </>
                                  ) : null}
                                </Form.Control>
                              </Form.Group>
                            </Col>
                            <Col md="12">
                              <Form.Group>
                                <label className="mb-0 mr-2">Specific Date:</label>
                                <DatePicker
                                  className="form-control"
                                  placeholderText="Select Date"
                                  selected={currentSummaryDate}
                                  value={currentSummaryDate}
                                  disabled={loader}
                                  selectsStart
                                  timeInputLabel="Time:"
                                  onChange={this.handleCurrentDate}
                                  dateFormat="MM/dd/yyyy"
                                />
                              </Form.Group>
                            </Col>
                            <Col md="12">
                              <Form.Group>
                                <label className="mb-0 mr-2">Start Date(Date Range):</label>
                                <DatePicker
                                  className="form-control"
                                  placeholderText="Select Start Date"
                                  selected={startDate}
                                  defaultHour={24}
                                  value={startDate}
                                  disabled={loader}
                                  selectsStart
                                  timeInputLabel="Time:"
                                  onChange={this.handleChangeStartingDates}
                                  dateFormat="MM/dd/yyyy"
                                  startDate={startDate}
                                  endDate={endDate}
                                  maxDate={new Date()}
                                />
                              </Form.Group>
                            </Col>
                            <Col md="12">
                              <Form.Group>
                                <label className="mb-0 mr-2">End Date(Date Range):</label>
                                <DatePicker
                                  className="form-control"
                                  placeholderText="Select End Date"
                                  selected={endDate}
                                  disabled={loader}
                                  value={endDate}
                                  selectsEnd
                                  timeInputLabel="Time:"
                                  onChange={this.handleChangeEndDates}
                                  // dateFormat="MM/dd/yyyy hh:mm:ss aa"
                                  dateFormat="MM/dd/yyyy"
                                  // showTimeInput
                                  startDate={startDate}
                                  endDate={endDate}
                                  minDate={startDate}
                                  maxDate={maxEndDate}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Card.Body>
                        <Card.Footer className="text-right justify-content-between d-flex">
                          <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={this.resetAll} disabled={loader}>
                            Reset
                          </Button>
                          <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={this.handleSearch} disabled={loader}>
                            Search
                          </Button>
                        </Card.Footer>
                      </Card>
                    </div>
                    <header>
                      <div className="active-permit-header sm-flex-column sm-align-items-start">
                        <h5 className="mb-2 mb-md-0">Permit Log Report</h5>
                        <div className="filter-button d-flex flex-wrap">
                          <div className="d-flex align-items-center search-mb-0">
                            <DatatableHeader
                              className="mt-0 mb-0"
                              searchValue={searchValue}
                              HandleChange={this.handleSearchChange}
                              // findMorePressed={this.findMorePressed}
                            />
                          </div>
                          <div className="ml-2">
                            <Button
                              variant="info"
                              className="btn-fill btn-sm"
                              onClick={this.handleExport}
                              disabled={accessLogList.length > 0 ? 0 : 1}
                              size="medium"
                            >
                              Export
                            </Button>
                          </div>
                        </div>
                      </div>
                    </header>
                  </>
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      {accessLogList && (
                        <>
                          <div className="citation-list-table">{this.dataTableHandle(accessLogList)}</div>
                          <DateRangeAndAmount startDateTime={startDateTime} endDateTime={endDateTime} />
                        </>
                      )}
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, Logindetails, SuperAdminDetails, CurrentPermitDetails, ReportsDetails }) => {
  const { property_id: PropertyId, timezone, property } = UserProfileDetails;
  const { backgroundColor } = Logindetails;
  const { residentList } = CurrentPermitDetails;
  const { getAllProperties, lots } = SuperAdminDetails;
  const { MainBackground: main_body } = backgroundColor;
  const { accessLogList: accessLogList } = ReportsDetails;
  return {
    PropertyId,
    timezone,
    property,
    accessLogList,
    main_body,
    getAllProperties,
    residentList,
    lots,
  };
};

const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
  get_all_properties: SuperAdminAction.get_all_properties,
  selected_Property: SuperAdminAction.selected_Property,
  get_all_lots: SuperAdminAction.get_all_lots,
  getReportList: ReportsAction.accessloghistoryList,
};

export default connect(mapStateToProps, mapStateToDispatch)(AccessLogReport);
