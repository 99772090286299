import React, { Component } from "react";
import { Card, Row, Col, Button, Form, Container, Tooltip, OverlayTrigger } from "react-bootstrap";
import isEqual from "lodash/isEqual";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { SuperAdminAction, ReportsAction } from "../../Actions";
import Loader from "../Loader/Loader";
import { tableHeightScrollBars, findBySearch, utcToSpecificTimeZone, scroolToTop, AddTimeInDate } from "../helper";
import "react-datepicker/dist/react-datepicker.css";
import PropertDetails from "../LandingPage/PropertySelection";
import { saveAs } from "file-saver";
import moment from "moment";
import "react-image-lightbox/style.css";
import DateRangeAndAmount from "../DateRangeAndAmount/DateRangeAndAmount";

class SessionOrStatsReport extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 3);
    this.state = {
      PropertyId: this.props.PropertyId,
      sessionorstatsList: [],
      loader: false,
      searchValue: "",
      count: 0,
      timezone: this.props.timezone,
      property: this.props.property,
      selectedProperty: "",
      selectedPropertyObj: {},
      all_properties: [],
      childcheckbox: true,
      SessionOrStatsReportLots: [],
      lot_id: "",
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      startDateTime: moment(new Date(datebeforeThirtyDays)).format("YYYY-MM-DD HH:mm:ss"),
      endDateTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      currentSummaryDate: "",
      finalTableHeight: 0,
      filterOption: "all",
      maxEndDate: new Date(),
      isDurationFilterVisible: false, // To toggle visibility
      searchedaccessLogList: [],
      selectedOption: "",
      selectedFilterOption: "",
      lpnsearch: "",
      lpn: "",
      durationFilterType: "",
      durationMinutes: "",
      showDurationFilter: false,
    };

    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    //Main Report
    this.columns = [
      {
        name: "ID",
        width: "120px",
        sortable: true,
        cell: (row) => this.showFields(row.id),
        selector: (row) => row?.id,
      },
      {
        name: "Domain",
        width: "140px",
        sortable: true,
        cell: (row) => this.showFields(row.domain),
        selector: (row) => row?.domain,
      },
      {
        name: "Lot",
        width: "140px",
        sortable: true,
        cell: (row) => this.showFields(row.alias),
        selector: (row) => row?.alias,
      },
      {
        name: "LPN",
        width: "140px",
        sortable: true,
        cell: (row) => this.showFields(row.lpn),
        selector: (row) => row?.lpn,
      },
      {
        name: "Entry Time",
        width: "140px",
        sortable: true,
        cell: (row) => this.showTimeField(row.entry_time),
        selector: (row) => row?.entry_time,
      },
      {
        name: "Exit Time",
        width: "140px",
        sortable: true,
        cell: (row) => this.showTimeField(row.exit_time),
        selector: (row) => row?.exit_time,
      },
      {
        name: "Duration",
        width: "140px",
        sortable: true,
        cell: (row) => this.showFields(row.duration_dhms),
        selector: (row) => row?.duration_dhms,
      },
      {
        name: "Notes",
        width: "140px",
        sortable: true,
        cell: (row) => this.mapErrorReason(row.error_reason),
        selector: (row) => row?.error_reason,
      },
    ];
  }

  showFields = (row) => (
    <>
      {row ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showTimeField = (row) => (
    <>
      {row != null ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement="top"
          overlay={<Tooltip id="tooltip-qw">{utcToSpecificTimeZone(this.props.timezone, row, true)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">{utcToSpecificTimeZone(this.props.timezone, row, true)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  mapErrorReason = (errorReason) => {
    if (!errorReason) return "";
    const lower = errorReason.toLowerCase();
    if (lower === "missing entry") return "exit, no entry";
    if (lower === "entry discrepancy") return "2nd entry, no exit";
    if (lower.startsWith("session timeout")) return "car in lot over timelimit";
    return errorReason;
  };

  handleCheckBox = () => {
    this.setState({ childcheckbox: !this.state.childcheckbox });
  };

  handleFilterChange = (value) => {
    this.setState({ selectedOption: value, showDurationFilter: value === "in_lot" });
    this.showDurationFilter(); // Call your filter logic
  };

  showDurationFilter = () => {
    // Your custom function logic here
  };

  handleDurationFilterTypeChange = (event) => {
    this.setState({ durationFilterType: event.target.value });
  };

  handleDurationMinutesChange = (event) => {
    this.setState({ durationMinutes: event.target.value });
  };
  componentDidMount = async () => {
    const { startTimeFormat, EndTimeFormat } = AddTimeInDate(this.state.startDate, this.state.endDate);
    await this.SessionOrStats();
    await this.props.get_all_properties();
    const { PropertyId } = this.state;
    await this.props.get_all_lots(PropertyId);
    const current_lot = this.props.lots;
    this.setState(
      {
        all_properties: this.props?.getAllProperties,
        SessionOrStatsReportLots: current_lot,
        startDateTime: startTimeFormat,
        endDateTime: EndTimeFormat,
      },
      async () => {
        const selectedPropertyObj = this.state.all_properties.find((item) => item.id === PropertyId);
        if (selectedPropertyObj) {
          this.setState({
            selectedPropertyObj: {
              label: selectedPropertyObj.site_name,
              value: selectedPropertyObj.id,
            },
            selectedProperty: PropertyId,
            selectedDomainName: selectedPropertyObj.site_name,
          });
        }
      },
    );

    const exportUrl = process.env.REACT_APP_CONFIG_BASE_URL;
    if (exportUrl) {
      this.setState({
        exportUrl: `${exportUrl}/api/v1/revenue_report/export/${this.state?.property?.id}`,
        exportPdfUrl: `${exportUrl}/api/v1/revenue_report/exportPdf`,
      });
    }
    const height = tableHeightScrollBars();
    this.setState({
      finalTableHeight: height,
    });
  };

  SessionOrStats = async () => {
    this.setState({ loader: true }, async () => {
      const { PropertyId, childcheckbox, timezone, startDate, endDate, selectedOption, selectedFilterOption, lpn } = this.state;
      const pageobj = {
        PropertyId,
        timezone,
        childcheckbox,
        selectedOption,
        selectedFilterOption,
        lpn,
      };
      if (startDate) {
        pageobj.startDate = startDate;
      }
      if (endDate) {
        pageobj.endDate = endDate;
      }
      await this.props.getReportList(pageobj);
      const selectedPropertyName = this.state.property?.property_name;
      const selectedDomainName = this.state.property?.domain;
      await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      this.setState({
        sessionorstatsList: this.props?.sessionorstatsList?.rows?.length ? JSON.parse(JSON.stringify(this.props.sessionorstatsList.rows)) : [],
        count: this.props.sessionorstatsList.count,
      });
      this.setState({ loader: false });
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (!isEqual(prevProps.sessionorstatsList, this.props.sessionorstatsList)) {
      this.setState({
        sessionorstatsList: JSON.parse(JSON.stringify(this.state.sessionorstatsList)),
        pageChanged: true,
        count: this.props.sessionorstatsList.count,
      });
    }
    if (!isEqual(prevProps.lots, this.props.lots)) {
      this.setState({
        SessionOrStatsReportLots: this.props.lots,
      });
    }
  };

  dataTableHandle = (sessionorstatsList) => {
    return (
      <DataTable
        data={this.state.searchValue.length ? this.state.searchedsessionorstatsList : sessionorstatsList}
        columns={this.columns}
        customStyles={this.customStyles}
        responsive={true}
        defaultSortAsc={true}
        onChangePage={scroolToTop}
        fixedHeader={true}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  handleChangeLot = ({ target }) => {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 3);
    this.setState({
      [target.name]: target.value,
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      currentSummaryDate: null,
    });
  };

  handleChangeStatsOptions = (event) => {
    this.setState({ selectedOption: event.target.value });
    console.log(event.target.value);
  };

  handleChangeFilterOptions = (event) => {
    this.setState({ selectedFilterOption: event.target.value });
    console.log(event.target.value);
    switch (event.target.value) {
      case "In Lot":
        this.setState({ showDurationFilter: true });
        break;
      case "Completed":
        this.setState({ showDurationFilter: true });
        break;
      case "Error":
        this.setState({ showDurationFilter: false });
        // Add logic for hourly case
        break;
      default:
        this.setState({ showDurationFilter: false });
    }
  };

  handleCurrentDate = (date) => {
    this.setState({
      currentSummaryDate: date,
      startDate: null,
      endDate: null,
    });
  };

  handleChangeStartingDates = (date) => {
    if (date) {
      const end = new Date(date);
      end.setDate(date.getDate() + 7);
      let endDate = new Date();
      if (new Date() >= end) {
        endDate = end;
      }
      this.setState({
        startDate: date,
        endDate: endDate,
        maxEndDate: new Date(endDate),
        currentSummaryDate: null,
      });
    }
  };

  handleChangeEndDates = (date) => {
    if (date) {
      this.setState({
        endDate: date,
        currentSummaryDate: null,
      });
    }
  };

  handleSearch = () => {
    this.setState({ loader: true }, async () => {
      const {
        lot_id,
        childcheckbox,
        currentSummaryDate,
        startDate,
        endDate,
        selectedPropertyObj,
        PropertyId,
        timezone,
        selectedOption,
        selectedFilterOption,
        lpn,
        durationFilterType,
        durationMinutes,
      } = this.state;
      const pageobj = {
        childcheckbox,
        PropertyId,
        timezone,
        selectedOption,
        selectedFilterOption,
        lpn,
        durationFilterType,
        durationMinutes,
      };
      let start, end;
      if (Object.keys(selectedPropertyObj).length) {
        pageobj.PropertyId = selectedPropertyObj.value;
      } else {
        pageobj.PropertyId = PropertyId;
      }
      if (lot_id) {
        pageobj.lot_id = lot_id;
      }
      if (currentSummaryDate) {
        pageobj.currentSummaryDate = currentSummaryDate;
        const { startTimeFormat, EndTimeFormat } = AddTimeInDate(currentSummaryDate, currentSummaryDate);
        start = startTimeFormat;
        end = EndTimeFormat;
      }
      if (startDate && endDate) {
        pageobj.startDate = startDate;
        pageobj.endDate = endDate;
        const { startTimeFormat, EndTimeFormat } = AddTimeInDate(startDate, endDate);
        start = startTimeFormat;
        end = EndTimeFormat;
      }

      switch (selectedOption) {
        case "None":
          this.columns = [
            {
              name: "ID",
              width: "120px",
              sortable: true,
              cell: (row) => this.showFields(row.id),
              selector: (row) => row?.id,
            },
            {
              name: "Domain",
              width: "140px",
              sortable: true,
              cell: (row) => this.showFields(row.domain),
              selector: (row) => row?.domain,
            },
            {
              name: "Lot",
              width: "140px",
              sortable: true,
              cell: (row) => this.showFields(row.alias),
              selector: (row) => row?.alias,
            },
            {
              name: "LPN",
              width: "140px",
              sortable: true,
              cell: (row) => this.showFields(row.lpn),
              selector: (row) => row?.lpn,
            },
            {
              name: "Entry Time",
              width: "140px",
              sortable: true,
              cell: (row) => this.showTimeField(row.entry_time),
              selector: (row) => row?.entry_time,
            },
            {
              name: "Exit Time",
              width: "140px",
              sortable: true,
              cell: (row) => this.showTimeField(row.exit_time),
              selector: (row) => row?.exit_time,
            },
            {
              name: "Duration",
              width: "140px",
              sortable: true,
              cell: (row) => this.showFields(row.duration_dhms),
              selector: (row) => row?.duration_dhms,
            },
            {
              name: "Notes",
              width: "140px",
              sortable: true,
              cell: (row) => this.mapErrorReason(row.error_reason),
              selector: (row) => row?.error_reason,
            },
          ];

          break;
        case "Statistics on season creation":
          // Add logic for session_time case
          //Session Stats
          this.columns = [
            {
              name: "Session Date",
              width: "120px",
              sortable: true,
              cell: (row) => this.showFields(row.session_date),
              selector: (row) => row?.session_date,
            },
            {
              name: "Total Sessions",
              width: "140px",
              sortable: true,
              cell: (row) => this.showFields(row.total_sessions),
              selector: (row) => row?.total_sessions,
            },
            {
              name: "Completed Sessions",
              width: "140px",
              sortable: true,
              cell: (row) => this.showFields(row.completed_sessions),
              selector: (row) => row?.completed_sessions,
            },
            {
              name: "Error Total",
              width: "140px",
              sortable: true,
              cell: (row) => this.showFields(row.error_total),
              selector: (row) => row?.error_total,
            },
            {
              name: "Missing Entry",
              width: "140px",
              sortable: true,
              cell: (row) => this.showFields(row.missing_entry),
              selector: (row) => row?.missing_entry,
            },
            {
              name: "Missing Exit",
              width: "140px",
              sortable: true,
              cell: (row) => this.showFields(row.missing_exit),
              selector: (row) => row?.missing_exit,
            },
            {
              name: "Timeout",
              width: "140px",
              sortable: true,
              cell: (row) => this.showFields(row.timeout),
              selector: (row) => row?.timeout,
            },
          ];
          break;

        case "Distribution of session length on given dates":
          // Add logic for distinct_plates case
          this.columns = [
            {
              name: "Duration Range",
              width: "120px",
              sortable: true,
              cell: (row) => this.showFields(row.duration_range),
              selector: (row) => row?.duration_range,
            },
            {
              name: "Session Count",
              width: "140px",
              sortable: true,
              cell: (row) => this.showFields(row.session_count),
              selector: (row) => row?.session_count,
            },
          ];
          break;

        case "Unique Visitors: Distinct Plates on given dates":
          this.columns = [
            {
              name: "Number Of Times Seen",
              width: "120px",
              sortable: true,
              cell: (row) => this.showFields(row.number_of_times_seen),
              selector: (row) => row?.Number_Of_Times_Seen,
            },
            {
              name: "Number Of Plates",
              width: "140px",
              sortable: true,
              cell: (row) => this.showFields(row.number_of_plates),
              selector: (row) => row?.session_count,
            },
          ];
          break;

        case "Average Distribution of session length":
          this.columns = [
            {
              name: "Day",
              width: "120px",
              sortable: true,
              cell: (row) => this.showFields(row.day),
              selector: (row) => row?.day,
            },
            {
              name: "From - To",
              width: "140px",
              sortable: true,
              cell: (row) => this.showFields(row.from_to),
              selector: (row) => row?.from_to,
            },
            {
              name: "Entries",
              width: "140px",
              sortable: true,
              cell: (row) => this.showFields(row.entries),
              selector: (row) => row?.entries,
            },
            {
              name: "Exits",
              width: "140px",
              sortable: true,
              cell: (row) => this.showFields(row.exits),
              selector: (row) => row?.exits,
            },
          ];
        case "Session per Week":
          break;
        case "Daily":
          break;
        case "Hourly":
          this.columns = [
            {
              name: "Date",
              width: "120px",
              sortable: true,
              cell: (row) => this.showFields(row.date),
              selector: (row) => row?.day,
            },
            {
              name: "From - To",
              width: "140px",
              sortable: true,
              cell: (row) => this.showFields(row.from_to),
              selector: (row) => row?.from - to,
            },
            {
              name: "Entries",
              width: "140px",
              sortable: true,
              cell: (row) => this.showFields(row.entries),
              selector: (row) => row?.entries,
            },
            {
              name: "Exits",
              width: "140px",
              sortable: true,
              cell: (row) => this.showFields(row.exits),
              selector: (row) => row?.exits,
            },
          ];
          // Add logic for hourly case
          break;
        default:
      }

      await this.props.getReportList(pageobj);
      console.log(this.state.sessionorstatsList);
      this.setState({
        sessionorstatsList: JSON.parse(JSON.stringify(this.props.sessionorstatsList.rows)),
        count: this.props.sessionorstatsList.count,
        startDateTime: start,
        endDateTime: end,
      });
      this.setState({ loader: false });
      document.body.classList.remove("foc-open");
    });
  };

  handleSearchChange = async ({ target }) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();

    this.setState({ searchValue: val }, async () => {
      const columnList = ["id", "domain", "alias", "lpn", "entry_time", "exit_time", "duration_dhms"];
      if (this.state.searchValue.length) {
        this.setState(
          {
            searchedsessionorstatsList: findBySearch(
              this.state.searchValue,
              this.state.sessionorstatsList,
              this.state.sessionorstatsList,
              columnList,
              this.props.timezone,
            ),
          },
          () => {
            this.setState({ count: this.state.searchedsessionorstatsList.length });
          },
        );
      } else {
        this.setState({
          count: this.props.sessionorstatsList.count,
          searchedsessionorstatsList: [],
          sessionorstatsList: this.props.sessionorstatsList.rows,
        });
      }
    });
  };

  resetAll = async () => {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 3);
    const { startTimeFormat, EndTimeFormat } = AddTimeInDate(new Date(datebeforeThirtyDays), new Date());
    this.setState({
      selectedRadio: null,
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      startDateTime: startTimeFormat,
      endDateTime: EndTimeFormat,
      currentSummaryDate: null,
      selectedPropertyObj: {},
      lot_id: "",
      childcheckbox: true,
      lpn: "",
      durationFilterType: "",
      durationMinutes: "",
      // exportButtonDisable: true,
    });
    const {
      page,
      Norow,
      selectedColumn,
      sort_direction,
      PropertyId,
      startDate,
      endDate,
      selectedDomainName,
      timezone,
      selectedOption,
      selectedFilterOption,
      lpn,
      durationFilterType,
      durationMinutes,
    } = this.state;
    const pageobj = {
      page,
      Norow,
      selectedColumn,
      sort_direction,
      PropertyId,
      timezone,
      selectedOption,
      selectedFilterOption,
      lpn,
      durationFilterType,
      durationMinutes,
    };
    if (startDate) {
      pageobj.startDate = new Date(datebeforeThirtyDays);
    }
    if (endDate) {
      pageobj.endDate = new Date();
    }
    await this.props.getReportList(pageobj);
    this.setState({
      pageChanged: true,
      page: 1,
      sessionorstatsList: this.props.sessionorstatsList.rows,
      count: this.props.sessionorstatsList.count,
      selectedPropertyObj: {
        label: selectedDomainName,
        value: PropertyId,
      },
    });
    setTimeout(() => {
      this.setState({ findMore: false });
    }, 2000);
    this.setState({ loader: false });
    document.body.classList.remove("foc-open");
  };

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  handleExport = async () => {
    const {
      page,
      Norow,
      selectedColumn,
      sort_direction,
      lot_id,
      childcheckbox,
      currentSummaryDate,
      startDate,
      endDate,
      selectedPropertyObj,
      PropertyId,
      selectedDomainName,
      timezone,
      selectedFilterOption,
      selectedOption,
      lpn,
      durationFilterType,
      durationMinutes,
    } = this.state;
    const pageobj = {
      page,
      Norow,
      selectedColumn,
      sort_direction,
      childcheckbox,
      PropertyId,
      timezone,
      exportValue: true,
      selectedFilterOption,
      selectedOption,
      lpn,
      durationFilterType,
      durationMinutes,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.PropertyId = selectedPropertyObj.value;
    } else {
      pageobj.PropertyId = PropertyId;
    }
    if (lot_id) {
      pageobj.lot_id = lot_id;
    }
    if (currentSummaryDate) {
      pageobj.currentSummaryDate = currentSummaryDate;
    }
    if (startDate && endDate) {
      pageobj.startDate = startDate;
      pageobj.endDate = endDate;
    }
    const bufferData = await this.props.getReportList(pageobj);
    const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
    const exportDataName = `Sessions_or_Stats-${currentTimeDate}-${selectedDomainName}.xlsx`;
    if (bufferData) {
      saveAs(
        new Blob([bufferData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        exportDataName,
      );
    }
  };

  changeProperty(event) {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 3);
    this.setState(
      {
        selectedProperty: event.value,
        selectedPropertyObj: event,
        startDate: new Date(datebeforeThirtyDays),
        endDate: new Date(),
        currentSummaryDate: null,
      },
      () => {
        const selectedPropertyObj = this.state.all_properties.find((item) => item.id === this.state.selectedProperty);

        this.props.selected_Property(selectedPropertyObj);
        this.props.get_all_lots(event.value);
        const current_lot = this.props.lots;
        this.setState({
          SessionOrStatsReportLots: current_lot,
        });
        this.setState({
          isFormValid: true,
          pageDropdownDisabled: false,
        });
      },
    );
  }

  handleFilterTypeChange = (event) => {
    this.setState({ durationFilterType: event.target.value });
  };

  handleMinutesChange = (event) => {
    this.setState({ durationMinutes: event.target.value });
  };

  toggleDurationFilter = () => {
    this.setState((prevState) => ({
      isDurationFilterVisible: !prevState.isDurationFilterVisible,
    }));
  };

  handleChangeLPN = (event) => {
    this.setState({ lpn: event.target.value });
  };

  render() {
    const {
      sessionorstatsList,
      loader,
      childcheckbox,
      SessionOrStatsReportLots,
      lot_id,
      startDate,
      endDate,
      currentSummaryDate,
      searchValue,
      maxEndDate,
      startDateTime,
      endDateTime,
      lpn,
      showDurationFilter,
      durationFilterType,
      durationMinutes,
    } = this.state;

    const options = [
      "None",
      "Statistics on season creation",
      "Distribution of session length on given dates",
      "Unique Visitors: Distinct Plates on given dates",
      "Average Distribution of session length",
      "Hourly",
    ];

    console.log(this.state.selectedOption);
    const selectionGreaterLessthen = ["Select", "Less Than", "Greater Than"];

    /*const options = [
      "None",
      "Statistics on season creation",
      "Distribution of session length on given dates",
      "Average Distribution of session length",
      "Session per Week",
      "Weekly",
      "Daily",
      "Hourly",
    ];*/

    const filterOption = ["All", "In Lot", "Completed", "Error"];

    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="mb-0">
                <Card.Body className="pos-rel-overflow-hide">
                  <>
                    <div className="sidebarFilter">
                      <Card className="contact-container mb-0">
                        <Card.Header style={{ backgroundColor: this.props.main_body }}>
                          <div className="d-flex align-items-center justify-content-between">
                            <Card.Title as="h4">Session/Stats Report</Card.Title>
                            <a
                              onClick={this.hideSlider}
                              href="javascript:void(0);"
                              className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </a>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <Row>
                            <Col md="12">
                              <div className="mb-2">
                                <label className="fancy-checkbox">
                                  <input
                                    type="checkbox"
                                    name={"childcheckbox"}
                                    checked={childcheckbox}
                                    onChange={this.handleCheckBox}
                                    className="custom-control-input"
                                    id="childcheckbox1"
                                  ></input>
                                  <span>
                                    <i></i>Include Children Sites
                                  </span>
                                </label>
                              </div>
                            </Col>
                            <Col md="12">
                              <PropertDetails selectedPropertyObj={this.state.selectedPropertyObj} changeProperty={this.changeProperty.bind(this)} />
                            </Col>
                            <Col md="12">
                              <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>LOTS:</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="lot_id"
                                  value={lot_id}
                                  onChange={this.handleChangeLot}
                                  disabled={SessionOrStatsReportLots.length < 1}
                                  placeholder="Select Lot"
                                >
                                  {SessionOrStatsReportLots?.length ? (
                                    <>
                                      <option value="">Any</option>
                                      {SessionOrStatsReportLots.map((item, index) => (
                                        <option key={index} value={item.id}>
                                          {item.alias}
                                        </option>
                                      ))}
                                    </>
                                  ) : null}
                                </Form.Control>
                              </Form.Group>
                            </Col>
                            <Col md="12">
                              <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>Filter:</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="lot_id"
                                  value={this.state.selectedFilterOption}
                                  onChange={this.handleChangeFilterOptions}
                                  disabled={filterOption.length < 1}
                                  placeholder="Select Stats"
                                >
                                  {filterOption?.length ? (
                                    <>
                                      {Object.entries(filterOption).map(([key, value]) => (
                                        <option key={value} value={value}>
                                          {value}
                                        </option>
                                      ))}
                                    </>
                                  ) : null}
                                </Form.Control>
                              </Form.Group>
                            </Col>

                            <Col md="12">
                              <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>Stats:</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="lot_id"
                                  value={this.state.selectedOption}
                                  onChange={this.handleChangeStatsOptions}
                                  disabled={options.length < 1}
                                  placeholder="Select Stats"
                                >
                                  {options?.length ? (
                                    <>
                                      {Object.entries(options).map(([key, value]) => (
                                        <option key={value} value={value}>
                                          {value}
                                        </option>
                                      ))}
                                    </>
                                  ) : null}
                                </Form.Control>
                              </Form.Group>
                            </Col>

                            <Col md="12">
                              <Form.Group>
                                <Form.Label>License Plate Number:</Form.Label>
                                <Form.Control
                                  className="form-control"
                                  type="text"
                                  name="vehicle_license"
                                  maxLength="11"
                                  value={lpn}
                                  onChange={this.handleChangeLPN}
                                ></Form.Control>
                              </Form.Group>
                            </Col>
                            {showDurationFilter && (
                              <Col md="12">
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                  <Form.Label>Duration Filter:</Form.Label>
                                  <Form.Control
                                    as="select"
                                    name="lot_id"
                                    value={durationFilterType}
                                    onChange={this.handleDurationFilterTypeChange}
                                    disabled={selectionGreaterLessthen.length < 1}
                                    placeholder="Select"
                                  >
                                    {selectionGreaterLessthen?.length ? (
                                      <>
                                        {Object.entries(selectionGreaterLessthen).map(([key, value]) => (
                                          <option key={value} value={value}>
                                            {value}
                                          </option>
                                        ))}
                                      </>
                                    ) : null}
                                  </Form.Control>
                                </Form.Group>
                              </Col>
                            )}
                            {showDurationFilter && (
                              <Col md="12">
                                <Form.Group>
                                  <Form.Label>Minutes:</Form.Label>
                                  <Form.Control
                                    className="form-control"
                                    name="minutes"
                                    maxlength="11"
                                    type="number"
                                    value={durationMinutes}
                                    onChange={this.handleDurationMinutesChange}
                                  ></Form.Control>
                                </Form.Group>
                              </Col>
                            )}
                            <Col md="12">
                              <Form.Group>
                                <label className="mb-0 mr-2">Specific Date:</label>
                                <DatePicker
                                  className="form-control"
                                  placeholderText="Select Date"
                                  selected={currentSummaryDate}
                                  value={currentSummaryDate}
                                  disabled={loader}
                                  selectsStart
                                  timeInputLabel="Time:"
                                  onChange={this.handleCurrentDate}
                                  dateFormat="MM/dd/yyyy"
                                />
                              </Form.Group>
                            </Col>
                            <Col md="12">
                              <Form.Group>
                                <label className="mb-0 mr-2">Start Date(Date Range):</label>
                                <DatePicker
                                  className="form-control"
                                  placeholderText="Select Start Date"
                                  selected={startDate}
                                  defaultHour={24}
                                  value={startDate}
                                  disabled={loader}
                                  selectsStart
                                  timeInputLabel="Time:"
                                  onChange={this.handleChangeStartingDates}
                                  dateFormat="MM/dd/yyyy h:mm aa"
                                  startDate={startDate}
                                  endDate={endDate}
                                  maxDate={new Date()}
                                />
                              </Form.Group>
                            </Col>
                            <Col md="12">
                              <Form.Group>
                                <label className="mb-0 mr-2">End Date(Date Range):</label>
                                <DatePicker
                                  className="form-control"
                                  placeholderText="Select End Date"
                                  selected={endDate}
                                  disabled={loader}
                                  value={endDate}
                                  selectsEnd
                                  timeInputLabel="Time:"
                                  onChange={this.handleChangeEndDates}
                                  // dateFormat="MM/dd/yyyy hh:mm:ss aa"
                                  dateFormat="MM/dd/yyyy h:mm aa"
                                  // showTimeInput
                                  startDate={startDate}
                                  endDate={endDate}
                                  minDate={startDate}
                                  maxDate={maxEndDate}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Card.Body>
                        <Card.Footer className="text-right justify-content-between d-flex">
                          <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={this.resetAll} disabled={loader}>
                            Reset
                          </Button>
                          <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={this.handleSearch} disabled={loader}>
                            Search
                          </Button>
                        </Card.Footer>
                      </Card>
                    </div>
                    <header>
                      <div className="active-permit-header sm-flex-column sm-align-items-start">
                        <h5 className="mb-2 mb-md-0">Session/Stats Report</h5>
                        <div className="filter-button d-flex flex-wrap">
                          <div className="d-flex align-items-center search-mb-0">
                            <DatatableHeader
                              className="mt-0 mb-0"
                              searchValue={searchValue}
                              HandleChange={this.handleSearchChange}
                              // findMorePressed={this.findMorePressed}
                            />
                          </div>
                          <div className="ml-2">
                            <Button
                              variant="info"
                              className="btn-fill btn-sm"
                              onClick={this.handleExport}
                              disabled={sessionorstatsList.length > 0 ? 0 : 1}
                              size="medium"
                            >
                              Export
                            </Button>
                          </div>
                        </div>
                      </div>
                    </header>
                  </>
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      {sessionorstatsList && (
                        <>
                          <div className="citation-list-table">{this.dataTableHandle(sessionorstatsList)}</div>
                          <DateRangeAndAmount startDateTime={startDateTime} endDateTime={endDateTime} />
                        </>
                      )}
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, Logindetails, SuperAdminDetails, CurrentPermitDetails, ReportsDetails }) => {
  const { property_id: PropertyId, timezone, property } = UserProfileDetails;
  const { backgroundColor } = Logindetails;
  const { residentList } = CurrentPermitDetails;
  const { getAllProperties, lots } = SuperAdminDetails;
  const { MainBackground: main_body } = backgroundColor;
  const { sessionorstatsList: sessionorstatsList } = ReportsDetails;
  return {
    PropertyId,
    timezone,
    property,
    sessionorstatsList,
    main_body,
    getAllProperties,
    residentList,
    lots,
  };
};

const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
  get_all_properties: SuperAdminAction.get_all_properties,
  selected_Property: SuperAdminAction.selected_Property,
  get_all_lots: SuperAdminAction.get_all_lots,
  getReportList: ReportsAction.getAllSessionsPerSiteOrStatsList,
};

export default connect(mapStateToProps, mapStateToDispatch)(SessionOrStatsReport);